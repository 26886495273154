<template>
  <div class="ci_container">
    <div class="ci_header">
      <div class="header_title" v-text="$tc('organization.folder', 3)" />
      <BaseButton
        icon="$mdiAccountArrowUp"
        type="primary"
        @click="importDialogOpen = true"
      >
        {{ $t("utils.import") }}
      </BaseButton>
      <BaseButton
        icon="$mdiFileDocument"
        type="primary"
        href="/serve/static/import/folder/sample/"
      >
        {{ $t("utils.downloadTemplate") }}
      </BaseButton>
    </div>
    <div class="ci_body">
      <template>
        <div v-if="!!filters.author" class="header_center">
          <div class="filters">
            <div class="filter_icon">
              <BaseIcon icon="$mdiFilterOutline" />
            </div>
            <div class="filter_item">
              <div class="filter_item_label">
                {{ `${$t("import.author")} : ${filters.author}` }}
              </div>
              <div class="close_icon" @click="removeFilter">
                <BaseIcon icon="$mdiClose" />
              </div>
            </div>
          </div>
        </div>
        <BaseDataTable
          :items="importFolderDetail.folderLists"
          :loading="loading"
          :loading-text="$t('utils.loadingData')"
          :server-items-length="importFolderDetail.count"
          class="elevation-1 mb-6"
          @update:items-per-page="(value) => updateFilters('page-size', value)"
          @update:page="(value) => updateFilters('page', value)"
        >
          <template #header>
            <thead>
              <tr>
                <th class="header-col">{{ $t("import.file") }}</th>
                <th class="header-col text-center">{{ $t("import.date") }}</th>
                <th class="header-col text-center">{{ $t("import.state") }}</th>
                <th
                  class="header-col d-flex justify-center align-center filtrable"
                >
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    :nudge-bottom="10"
                    bottom
                    min-width="auto"
                  >
                    <template #activator="{ on }">
                      <div v-on="on">
                        {{ $t("import.author") }}
                        <BaseIcon icon="$mdiMenuDown" />
                      </div>
                    </template>
                    <div class="filter_search_section">
                      <div class="search_header">
                        <div class="filter_icon">
                          <BaseIcon icon="$mdiFilterOutline" />
                        </div>
                        <div class="header_label">{{ $t("utils.filter") }}</div>
                      </div>
                      <div class="search_input">
                        <input
                          v-model="filters.author"
                          placeholder="Rechercher.."
                          class="input"
                          type="text"
                          @input="handleInputFilter"
                        />
                      </div>
                    </div>
                  </v-menu>
                </th>
              </tr>
            </thead>
          </template>
          <template #item="{ item }">
            <tr>
              <td>
                <div class="d-flex" @click="openUrl(item.file.url)">
                  <div class="label item_name">{{ item.file.name }}</div>
                  <BaseIcon icon="$mdiDownload" class="icon_name" />
                </div>
              </td>
              <td class="text-center">
                <div class="label">
                  {{ $d(new Date(item.dateCreated), "detailed") }}
                </div>
              </td>
              <td class="text-center">
                <BaseIcon
                  v-if="item.done"
                  :icon="getIconValue(item)"
                  size="small"
                  :class="{ downloadable: !item.fileErrors }"
                  @click="downloadFile(item.fileErrors)"
                />
                <v-progress-circular
                  v-else
                  size="24"
                  indeterminate
                  color="primary"
                />
              </td>
              <td class="text-center">
                <div class="label">{{ item.author }}</div>
              </td>
            </tr>
          </template>
        </BaseDataTable>
      </template>
    </div>
    <FolderImportDialog
      :dialog-state="importDialogOpen"
      :organization-id="organizationId"
      @close="importDialogOpen = false"
      @refetch-import="fetchImportFolders"
    />
  </div>
</template>

<script>
import FolderImportDialog from "@/modules/Client/Components/FolderImportDialog.vue";
import { getImportFolders } from "@/modules/Folder/Services/folder.service";

export default {
  name: "FolderImport",
  components: {
    FolderImportDialog,
  },
  props: {
    organizationId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      importDialogOpen: false,
      importFolderDetail: {
        folderLists: [],
        count: 0,
      },
      loading: true,
      filters: {
        author: null,
        page_size: 10,
        page: 1,
      },
    };
  },
  created() {
    this.fetchImportFolders();
  },
  methods: {
    getIconValue(item) {
      if (item.fileErrors) {
        return "$fileError";
      }
      return "$fileDone";
    },
    openUrl(url) {
      open(url);
    },
    removeFilter() {
      this.filters.author = null;
      this.fetchImportFolders();
    },
    downloadFile(url) {
      url && open(url);
    },
    async fetchImportFolders() {
      this.loading = true;
      try {
        const response = await getImportFolders({
          organizationId: this.organizationId,
          filters: {
            ...this.filters,
            author: this.filters.author == "" ? null : this.filters.author,
          },
        });
        this.importFolderDetail = {
          folderLists: [...response.value],
          count: response.count,
        };
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.loading = false;
    },
    handleInputFilter() {
      if (this.searchTimeoutRef) clearTimeout(this.searchTimeoutRef);
      this.searchTimeoutRef = setTimeout(async () => {
        this.fetchImportFolders();
      }, 500);
    },
  },
};
</script>

<style scoped lang="scss">
.ci_container {
  .ci_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    gap: 16px;
    .header_title {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: #353542;
      margin-top: 8px;
      margin-right: auto;
    }
  }
}
.ci_body {
  .alert_detail {
    text-align: center;
    padding: 16px;
    border-radius: 5px;
    background: #eeeef7;
    margin-bottom: 24px;
    &:last-child {
      margin: 0;
    }
  }
}
.label {
  color: var(--Principale-Gris-Noir, #353542);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.item_name {
  font-weight: 600;
  text-decoration: underline;
}
::v-deep .icon_name {
  .v-icon__svg {
    width: 18px;
    height: 18px;
    color: #4f4f4f;
    transform: translateY(-2px);
  }
}
.filter_search_section {
  padding: 10px 16px;
  background: white;
  position: relative;
  .progression {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  .search_header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .filter_icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
      &::v-deep {
        .v-icon {
          color: #484964;
          width: 16px;
          height: 16px;
        }
      }
    }
    .header_label {
      color: #484964;
      font-family: "Inter";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
  .search_input {
    width: 100%;
    .input {
      color: #353542;
      font-family: "Inter";
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
      padding: 6px 16px;
      border: 1px solid #000;
      border-radius: 5px;
      width: 100%;
      &:focus {
        border: 1px solid #2536cc;
        outline: 3px solid #ebf2ff;
      }
    }
    &::v-deep {
      .v-input__append-inner {
        display: none;
      }

      .v-input {
        color: #353542;
        font-family: "Inter";
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        &.v-input--is-focused {
          outline: 3px solid #ebf2ff;
        }
      }
    }
  }
}
.header_center {
  width: calc(100% - 417px);
  overflow: hidden;
}
.filters {
  display: flex;
  padding: 0px 12px 12px 12px;
  align-items: center;
  .filter_icon {
    flex: none;
    background: #f4f7ff;
    border-radius: 9px;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    &::v-deep {
      .v-icon {
        height: 20px;
        width: 20px;
        color: #242533;
      }
    }
  }

  .filter_item {
    border-radius: 36px;
    background: #f4f7ff;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    .filter_item_icon {
      width: 18px;
      height: 18px;
      margin-right: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      &::v-deep {
        .v-icon {
          height: 18px;
          width: 18px;
          color: #707080;
        }
      }
    }
    .filter_item_label {
      color: #242533;
      font-family: "Inter";
      font-size: 12px;
      line-height: 20px;
      font-style: normal;
      font-weight: 500;
    }
    .close_icon {
      width: 18px;
      height: 18px;
      margin-left: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &::v-deep {
        .v-icon {
          height: 18px;
          width: 18px;
          color: #2536cc;
        }
      }
    }
  }
}
.header-col {
  color: #353542 !important;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
}
.filtrable {
  cursor: pointer;
}
::v-deep .downloadable {
  &.v-icon--link {
    cursor: not-allowed !important;
  }
}
</style>
