<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <BaseButton
      icon="$mdiPlus"
      @click="$store.commit('folder/setModalCreateAndEditFolder', true)"
    >
      {{ $tc("organization.createFolder", 1) }}
    </BaseButton>
    <BaseModal
      :value="dialogState"
      :modal-title="$tc('organization.createFolder', 1)"
      is-unique
      @close="reset"
    >
      <template #modal>
        <v-form ref="form" @submit.prevent="submit()">
          <BaseTextField
            v-model="form.name"
            autofocus
            :label="$tc('utils.name', 1) + '*'"
            :error-messages="nameErrors"
            :has-error="!!nameErrors"
            @change="$v.form.$touch()"
          />
          <base-autocomplete
            v-if="!isFetchingWorkflow"
            v-model="form.workflow"
            :items="filteredWorkflow"
            outlined
            item-text="name"
            item-value="id"
            label="Workflow"
            :hide-details="!!selectedCampaign"
          />
          <div
            v-if="selectedCampaign"
            class="campaign_alert"
            v-html="
              $t('workflowCampaignModal.alert', {
                campaignName: selectedCampaign.name,
              })
            "
          />
          <OrganizationContactSelector
            v-model="form.client"
            :organization-id="organizationId"
          />
        </v-form>
      </template>
      <template #actions>
        <BaseButton
          text
          class="mx-2 ml-auto"
          type="secondary"
          color="#707080"
          @click="reset"
        >
          {{ $t("utils.cancel") }}
        </BaseButton>
        <BaseButton type="primary" @click="submit">
          {{ $t("utils.validate") }}
        </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import FolderAPI from "@/modules/Folder/Services/folder.api";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import OrganizationContactSelector from "./OrganizationContactSelector";

export default {
  name: "FolderCreateAndEditDialog",
  components: {
    OrganizationContactSelector,
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: "",
        workflow: null,
        client: null,
      },
      isFetchingWorkflow: true,
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
  computed: {
    ...mapState({
      dialogState: (state) => state.folder.modals.isCreateAndEditDialogOpen,
      workflows: (state) => state.workflow.workflows,
    }),
    organizationId() {
      return parseInt(this.$route.params.organizationId);
    },
    filteredWorkflow() {
      return this.workflows.filter((wf) => wf.primaryStatus != null);
    },
    nameErrors() {
      if (!this.$v.form.name.$dirty) {
        return "";
      }
      if (!this.$v.form.name.required) {
        return this.$t("folder.createAndEditForm.validation.nameRequired");
      }
      if (!this.$v.form.name.maxLength) {
        return this.$t("folder.createAndEditForm.validation.nameTooLong");
      }
      return "";
    },
    selectedCampaign() {
      if (!this.form.workflow) return null;
      const tmp = this.workflows.find((w) => w.id == this.form.workflow);
      if (!tmp?.campaign) return null;
      return tmp.campaign;
    },
  },
  watch: {
    organizationId() {
      this.reset();
      this.initialize();
    },
  },
  mounted() {
    this.reset();
    this.initialize();
  },
  destroyed() {
    this.reset();
  },
  methods: {
    reset() {
      this.form = {
        name: "",
        workflow: null,
        client: null,
      };
      this.$v.form.$reset();
      this.$store.commit("folder/setModalCreateAndEditFolder", false);
    },

    async initialize() {
      this.isFetchingWorkflow = true;
      await this.$store.dispatch(
        "workflow/fetchWorkflows",
        this.organizationId
      );
      this.isFetchingWorkflow = false;
    },

    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      this.createFolder();
      this.reset();
    },
    async createFolder() {
      let payload = {
        name: this.form.name,
      };
      if (this.form.workflow) payload.workflow = this.form.workflow;
      if (this.form.client) payload.client = this.form.client;
      try {
        const folder = (
          await FolderAPI.createFolder(
            this.$route.params.organizationId,
            payload
          )
        ).data;
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.createAndEditForm.successCreate"),
          type: "SUCCESS",
        });
        this.$router.push({
          name: "folderDetail",
          params: {
            organizationId: this.$route.params.organizationId,
            folderId: folder.id,
          },
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.createAndEditForm.errorCreate"),
          type: "ERROR",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.campaign_alert {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 4px;
  margin-bottom: 24px;
  padding: 0 12px;
  b {
    font-weight: 600;
  }
}
</style>
