import { render, staticRenderFns } from "./FolderListTable.vue?vue&type=template&id=033ed52d&scoped=true"
import script from "./FolderListTable.vue?vue&type=script&lang=js"
export * from "./FolderListTable.vue?vue&type=script&lang=js"
import style0 from "./FolderListTable.vue?vue&type=style&index=0&id=033ed52d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "033ed52d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCheckbox,VPagination,VProgressLinear,VTooltip})
