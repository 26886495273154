<template>
  <BaseDialog max-width="800" persistent :value="dialogState">
    <template #modal>
      <BaseCosmoCard>
        <template #title>
          <div class="modal_header">
            <BaseTitle v-text="$t('importFolderModal.title')" />
            <BaseButtonIcon
              class="close_icon"
              icon="$mdiClose"
              :disabled="isSubmiting"
              @click="reset()"
            />
          </div>
        </template>
        <div class="modal_content">
          <v-progress-linear
            v-if="isSubmiting"
            class="progression"
            indeterminate
          />
          <v-file-input
            v-model="file"
            :label="$t('importContactModal.loadAFile')"
            accept=".csv, text/csv"
            :disabled="isSubmiting"
          />
        </div>
        <div class="modal_footer">
          <BaseButton
            :disabled="isSubmiting"
            color="grey"
            type="secondary"
            @click="reset()"
          >
            {{ $t("utils.cancel") }}
          </BaseButton>
          <BaseButton
            :disabled="isSubmiting || !file"
            color="primary"
            type="primary"
            @click="submit()"
          >
            {{ $t("utils.import") }}
          </BaseButton>
        </div>
      </BaseCosmoCard>
    </template>
  </BaseDialog>
</template>

<script>
import { postImportFolders } from "@/modules/Folder/Services/folder.service";

export default {
  name: "FolderImportDialog",
  props: {
    dialogState: {
      type: Boolean,
    },
    organizationId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isSubmiting: false,
      file: null,
    };
  },
  methods: {
    reset() {
      if (this.isSubmiting) return;
      this.file = null;
      this.$emit("close");
    },
    async submit() {
      this.isSubmiting = true;
      try {
        await postImportFolders({
          organizationId: this.organizationId,
          file: this.file,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("importFolderModal.notif"),
          type: "SUCCESS",
        });
        this.$emit("refetch-import");
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.isSubmiting = false;
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 8px;
  flex-wrap: wrap;
  .modal_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #353542;
  }
  .close_icon {
    margin-left: auto;
  }
}
.modal_content {
  padding-top: 24px;
  position: relative;
  .progression {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .notif {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 171.52%;
    text-align: center;
    color: #2536cc;
    padding: 0 16px;
    margin-bottom: 24px;
  }
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding: 8px 0;
  gap: 16px;
}
</style>
