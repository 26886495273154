var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item_header",attrs:{"disabled":_vm.offer.status === 'CANCELED'},on:{"click":function($event){return _vm.changeExpandStatus()}}},[_c('div',{class:_vm.getClassHeader},[_c('BaseIcon',{staticClass:"icon",attrs:{"icon":"$mdiLogout"}})],1),_c('div',{staticClass:"sections"},[_c('div',{staticClass:"section_left"},[_c('div',{staticClass:"section_info"},[_c('div',{staticClass:"section_title",domProps:{"textContent":_vm._s(_vm.offer.title)}}),_c('div',{staticClass:"section_content",domProps:{"textContent":_vm._s(
            _vm.$t('folder.createdAt', {
              date: _vm.$d(new Date(_vm.offer.dateCreated), 'detailed'),
            })
          )}}),(_vm.offer.publishDate && _vm.isPublished)?_c('div',{staticClass:"section_content",domProps:{"textContent":_vm._s(
            _vm.$t('utils.scheduledOn', {
              date: _vm.$d(new Date(_vm.offer.publishDate), 'detailed'),
            })
          )}}):_vm._e(),_c('div',{staticClass:"section_content creator",domProps:{"textContent":_vm._s(
            _vm.$t('folder.offerCreator', {
              creatorName: _vm.offer.creator.email,
            })
          )}})])]),(_vm.offer.publishDate && !_vm.isPublished)?_c('div',{staticClass:"chips_container"},[_c('div',{staticClass:"scheduled_chips"},[_c('div',{staticClass:"chips_icon"},[_c('BaseIcon',{attrs:{"icon":"$scheduledMail"}})],1),_c('div',{staticClass:"chips_label",domProps:{"textContent":_vm._s(
            _vm.$t('utils.forDate', {
              date: _vm.$d(new Date(_vm.offer.publishDate), 'full'),
            })
          )}})])]):_vm._e(),_c('div',{staticClass:"section_center"},[_c('div',{staticStyle:{"margin-left":"45px"}},[_c('div',{staticClass:"section_title",domProps:{"textContent":_vm._s(_vm.$tc('utils.contact', 1))}}),(_vm.offerClients.length)?[(_vm.offerClients.length == 1)?[_c('div',{staticClass:"section_content",domProps:{"textContent":_vm._s(_vm.offerClients[0].fullName)}}),_c('div',{staticClass:"section_footer",class:{ text_primary: !_vm.isPending },domProps:{"textContent":_vm._s(_vm.offerClients[0].email)}})]:[_c('div',{staticClass:"section_content",domProps:{"textContent":_vm._s(
                _vm.offerClients[0].fullName
                  ? _vm.offerClients[0].fullName
                  : _vm.offerClients[0].email
              )}}),(_vm.otherContact)?_c('div',{staticClass:"section_content",domProps:{"textContent":_vm._s(_vm.otherContact)}}):_vm._e()]]:_vm._e()],2)]),_c('div',{staticClass:"section_right"},[_c('div',{staticClass:"chips",class:_vm.offerStatus.chipsClass},[_vm._v(" "+_vm._s(_vm.offerStatus.label)+" ")])])]),_c('div',{staticClass:"icon_right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.changeExpandStatus()}}},[_c('BaseIcon',{attrs:{"icon":_vm.expanded ? '$mdiChevronUp' : '$mdiChevronDown'}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }