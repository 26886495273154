var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTitle',{staticClass:"mb-6",domProps:{"textContent":_vm._s(_vm.$t('workflow.management'))}}),(_vm.activeWorkflow)?_c('div',[_c('Board',{staticClass:"mb-6",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"w_header"},[_c('BaseTitle',{domProps:{"textContent":_vm._s(_vm.activeWorkflow.name)}}),_c('BaseButton',{attrs:{"icon":"$mdiPencilOutline"},on:{"click":function($event){return _vm.$store.commit(
                'workflow/setModalCreateAndEditWorkflowDialog',
                true
              )}}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('utils.editName'))}})])],1)]},proxy:true},{key:"main",fn:function(){return [_c('BaseDivider',{staticClass:"my-5",attrs:{"grey":true}}),_c('div',{staticClass:"campaign_section"},[_c('div',{staticClass:"campaign_label"},[_c('span',{domProps:{"textContent":_vm._s(((_vm.$t('utils.automatedCampaign')) + " : "))}}),(_vm.activeWorkflow.campaign)?_c('router-link',{staticClass:"campaign_name",attrs:{"to":{
                name: 'campaignDetail',
                params: {
                  organizationId: _vm.organizationId,
                  campaignId: _vm.activeWorkflow.campaign.id,
                },
              }},domProps:{"textContent":_vm._s(_vm.activeWorkflow.campaign.name)}}):_vm._e()],1),_c('BaseButton',{attrs:{"icon":"$mdiPencilOutline"},on:{"click":function($event){_vm.openWorkflowCampaignDialog = true}}},[_c('span',{domProps:{"textContent":_vm._s(
                _vm.$t(_vm.activeWorkflow.campaign ? 'utils.edit' : 'utils.define')
              )}})])],1)]},proxy:true}],null,false,3018460886)}),_c('Board',{staticClass:"mb-6",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('BaseTitle',{domProps:{"textContent":_vm._s('Statuts')}}),_c('BaseButton',{attrs:{"icon":"$mdiPlus"},on:{"click":function($event){return _vm.$store.commit(
                'workflow/setModalCreateAndEditStatusDialog',
                true
              )}}},[_vm._v(" Créer un statut ")])],1)]},proxy:true},{key:"main",fn:function(){return [_vm._l((_vm.activeWorkflow.statuses),function(status,index){return _c('WorkflowDetailStatus',{key:index,attrs:{"index-status":index + 1,"status":status,"is-last-status":_vm.activeWorkflow.statuses.length - 1 === +index}})}),(_vm.activeWorkflow.statuses.length === 0)?_c('BaseAlert',{attrs:{"color":"secondary lighten-5 text-center"}},[_c('BaseParagraph',{attrs:{"bold":""},domProps:{"textContent":_vm._s(_vm.$t('workflow.status.noStatus'))}})],1):_vm._e()]},proxy:true}],null,false,3906462402)})],1):_vm._e(),_c('WorkflowCreateAndEditDialog'),_c('WorkflowCreateAndEditStatusDialog'),_c('WorkflowDeleteStatusDialog'),_c('WorkflowStatusQuestionCreateAndEditDialog'),_c('WorkflowStatusQuestionDeleteDialog'),_c('WorkflowStatusTargetStatusCreateAndEditDialog'),_c('WorkflowStatusTargetStatusDeleteDialog'),_c('WorkFlowCollaboratorsAddAndDelDialog'),_c('WorkflowRemoveStatusCategory'),_c('WorkflowEditStatusCategory'),(_vm.activeWorkflow && _vm.openWorkflowCampaignDialog)?_c('WorkflowCampaignDialog',{attrs:{"organization-id":_vm.organizationId,"workflow":_vm.activeWorkflow},on:{"close":function($event){_vm.openWorkflowCampaignDialog = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }